const gallery = [
    {original: 'https://i.ibb.co/b6VFcfL/M-GENGAGEMENT-1.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/xC5ncZg/M-GENGAGEMENT-7.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/nkFLYVn/M-GENGAGEMENT-2.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/fHSBF1t/M-GENGAGEMENT-3.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/7Wm8wW6/M-GENGAGEMENT-4.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/bHyqqxk/M-GENGAGEMENT-6.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/kgWm9Dg/M-GENGAGEMENT-8.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/vZFFVrb/M-GENGAGEMENT-9.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/ggVN81Z/M-GENGAGEMENT-15.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/6tgn08p/M-GENGAGEMENT-13.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/GJD21T6/M-GENGAGEMENT-16.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/hH8zwT2/M-GENGAGEMENT-12.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/0mynj3C/M-GENGAGEMENT-14.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/r2Rtd5y/M-GENGAGEMENT-18.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/yhXJ0Fn/M-GENGAGEMENT-17.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/sRPdkNj/M-GENGAGEMENT-19.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/KGdcgdg/M-GENGAGEMENT-20.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/Lry2Dqr/M-GENGAGEMENT-21.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/TbPTVCz/M-GENGAGEMENT-22.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/QC4xBb1/M-GENGAGEMENT-23.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/CQV7zNH/M-GENGAGEMENT-24.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/qr4LSsn/M-GENGAGEMENT-25.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/hg7wSr1/M-GENGAGEMENT-28.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/fqz2PXR/M-GENGAGEMENT-26.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'},
    {original: 'https://i.ibb.co/VSchyBV/M-GENGAGEMENT-35.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/Dg8tvZy/M-GENGAGEMENT-30.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/hyC1PHT/M-GENGAGEMENT-31.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/rttB9dV/M-GENGAGEMENT-32.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/VttyGVr/M-GENGAGEMENT-33.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/BtjhvT3/M-GENGAGEMENT-36.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'},
    {original: 'https://i.ibb.co/kDMfFYz/M-GENGAGEMENT-29.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
    {original: 'https://i.ibb.co/T0SkR2S/M-GENGAGEMENT-34.jpg', originalClass: '', description: 'cr: Eyes Wide Open', originalWidth: '100%'}, 
];

export default gallery;